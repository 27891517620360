import booklet from '../../../assets/files/ru/Справочник_вкладчика.pdf';
import certificate from '../../../assets/files/ru/Вх._корр._от_Государственная_корпорация_Агентство_по_страхованию_вкладов_№2024-00291_от_29._(13662v2).pdf';

export default {
    seo: {
      title: 'Страхование вкладов | Банк 131',
      description: 'Страхование вкладов'
    },
    title: 'Страхование вкладов',
    content: [],
}